const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '6045f1990ee44475316157c8',
    authMode: ['local'],
    authLocalOptions: {
      mode: 'unique_password',
      defaultLogin: 'digital+argedis@mediameeting.fr',
    },
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    disabledComponents: [
      'wButtonLike',
      'wTagLikeCount',
      'wCardsCommentsLabels',
      'wCardsLikeLabels'
    ],
    metadatas: {
      name: `Le plein d'énergies`,
      colorPrimary: '#4896cf',
      colorAccent: '#69605d',
      supportEmailAddress: 'contact@lepleindenergies.fr',
    },
    analytics: {
      matomo: '3',
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '646dd567e46f9504227dd13a',
            i18n: {
              fr: {
                title: "Le plein d'infos",
              },
            },
            heading: {
              color: '#1b99d7',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '62bafd4d09043d43bb8aca08',
            i18n: {
              fr: {
                title: 'Saison estivale',
              },
            },
            heading: {
              color: '#f28c56',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '605b54b980781c499872b5bf',
            i18n: {
              fr: {
                title: "Le plein d'innovation",
              },
            },
            heading: {
              color: '#80c7bc',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '6193c9733989a107c37e5cdb',
            i18n: {
              fr: {
                title: 'Le plein de sens',
              },
            },
            heading: {
              color: '#f7e38c',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
        ],
      },
    ],
    nuxt: {
      robots: {
        UserAgent: '*',
        Disallow: '',
      },
      head: {
        title: `Le Plein d'Énergies`,
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Bienvenue sur le site de votre podcast Le Plein d'Énergies. Le Plein d'Énergies c'est votre média 100% Argedis à découvrir le dernier jeudi de chaque mois`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'index,follow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content: `Le Plein d'Énergies`,
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content:
              `Bienvenue sur le site de votre podcast Le Plein d'Énergies. Le Plein d'Énergies c'est votre média 100% Argedis à découvrir le dernier jeudi de chaque mois`,
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
